.jobSummaryCard {
  width: 25em;
  display: inline-flex;
  padding: 1em;
  margin: 0.25em 1em;
  box-shadow: 0 0.1em 1em 0 rgba(0, 0, 0, 0.25);
  transition: 0.3s;
  border-radius: 0.25em;

  &:hover {
    box-shadow: 0 1em 2em 0 rgba(0, 0, 0, 0.3);
    transform: scale(105%);
  }
}

.imageColumn {
  flex: 30%;
}

.detailsColumn {
  flex: 70%;
}
.crawlerTaskPage {
  display: flex;
}

.jobsColumn {
  flex: 2;

  .jobsList {
    height: 100vh;
    overflow: scroll;
  }
}

.jobSummaryCardLink {
  text-decoration: none;

  &.selected {
    div {
      background-color: lightblue;
    }
  }
}

.jobDetailsColumn {
  flex: 3;
}

.highlightKeyword {
  background-color: lime;
}

.resultsCount {
  text-align: right;
}
.header {
  padding: 1em;

  .routeMatch {
    background-color: lightblue;
  }
}

.heading {
  margin: 1em;
}